import React from "react";
import "./Introduction.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ThemeContext } from "../../Context/theme";
import profilePic from "../../assets/Khushi.jpeg";


export const Introduction = () => {
  const [{ themename }] = React.useContext(ThemeContext);

  return (
    <>
      <section id="#about">
        <div className="section" data-aos="fade-right">
          <h2 className="section__title">
            About <span className="different">Me</span>
          </h2>
          <div className={"introduction " + themename}>
            <div className="introduction_logocontainer">
              <img src={profilePic} alt="Images" />
            </div>
            <div className="introduction_datacontainer">

            <h4>
            Hello! With a profound passion for data and transformative technology, I have honed a comprehensive skill set that includes advanced proficiency in Python, SQL, and R, supplemented by certifications in AWS and Microsoft Excel. My education at Syracuse University, where I completed a Master of Science in Information Systems and a Certificate of Advanced Study in Data Science, has equipped me for complex data analysis, visualization, and cloud-based solutions. I am adept at using platforms such as Tableau, Power BI, and Databricks, facilitating the deployment of sophisticated, cloud-enabled analytical tools. Currently, I am looking for opportunities to leverage my skills in practical applications, particularly in data analytics and cloud engineering, aiming to collaborate with industry professionals and contribute to innovative projects.
            </h4>
              



            {/* 
            
            <h4>Terms That can describe me apart form Coding :</h4>
              <h4 className="different">
                <span className="icons">
                  <ExitToAppIcon />
                </span>
                Entrepreneure{" "}
              </h4>
              <h4 className="different">
                <span className="icons">
                  <ExitToAppIcon />
                </span>
                Chess Enthusiast{" "}
              </h4>
              <h4 className="different">
                <span className="icons">
                  <ExitToAppIcon />
                </span>
                YouTuber{" "}
              </h4>
              <h4 className="different">
                <span className="icons">
                  <ExitToAppIcon />
                </span>
                Poet{" "}
              </h4>
            
            */}
              
            </div>
          </div>
          </div>
        </section>
      </>
  );
};
